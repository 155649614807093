.number-input-row th {
    width: 60%;
}
.number-input-row td {
    width: 40%;
}
.number-input {
    width: 100%;
    text-align: right;
} 
