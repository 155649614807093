.container {
    max-width: 70rem;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 0;
}
.subtitle {
    margin-top: .25em;
    font-size: 1.25rem;
}
h2 {
    font-size: 1.5rem;
}

h2 i {
    float: right;
    font-style: normal;
}

.calculated.row .label {
}
.calculated.row .value {
    text-align: right;
}

.help-link {
    display: inline-block;
    vertical-align: super;
    margin-left: .2em;
    font-size: 80%;
}
